interface RootVars {
  baseURL: string;
  nemBaseURL: string;
  socketURL: string;
}

//let nemDevHost: string = "localhost";
let nemDevHost: string = "localhost";
let devHost: string = "localhost";
let devPort: number = 3004;
let nemDevPort: number = 3005;

//Change this path to switch between dev and pro.
let nemProdHost: string = "nem.solbid.com";

let prodHost: string = "bes.solvault.com";
let prodPort: number = 3004;
let nemProdPort: number = 3005;

const prod: RootVars = {
  baseURL: `https://${prodHost}:${prodPort}/api`,
  //nemBaseURL: `https://${nemProdHost}:${nemProdPort}/api`,
  nemBaseURL: `https://${nemProdHost}/api`,
  socketURL: `https://${prodHost}:${prodPort}`,
};

const dev: RootVars = {
  baseURL: `http://${devHost}:${devPort}/api`,
  //nemBaseURL: `http://${nemDevHost}:${devPort}/api`,
  nemBaseURL: `http://${nemDevHost}/api`,
  socketURL: `http://${devHost}:${devPort}`,
};

let rootVars = process.env.NODE_ENV === "production" ? prod : dev;

export const appHost =
    process.env.NODE_ENV === "production" ? prodHost : devHost;
export default rootVars;