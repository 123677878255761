import React from "react";
import {
  Alignment,
  Button,
  Classes,
  Drawer,
  Menu,
  MenuDivider,
  MenuItem,
  Navbar,
  NavbarGroup,
} from "@blueprintjs/core";
import {
  getTheme,
  changeTheme,
  isMenuOpen,
  activateMenu,
} from "../../../app/app.slice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import LinkButton from "./LinkButton";
import { NavLink, useNavigate } from "react-router-dom";
import "../common/Navbar.style.css";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";

const HomeNavbar = (props: any) => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(getTheme);
  const isOpen = useAppSelector(isMenuOpen);
  const navigate = useNavigate();

  return (
    <>
      <Navbar
        fixedToTop={true}
        style={{
          background: "#1F1F1F",
          height: "auto",
          padding: "5px",
          marginBottom: "20px",
        }}
      >
        <div className={"main-navbar"}>
          <NavLink to={"/net-metering"}>
            <img
              alt={"solbid_logo"}
              src={
                "https://solbid-asset-control.s3.amazonaws.com/solbid_logo_light.png"
              }
              style={{ height: "40px" }}
            />
          </NavLink>
          <div className={"middle-container mobile-view"}>
            <Navbar.Group>
              <Button
                style={{
                  color: "#ffffff",
                  fontSize: "20px",
                  textAlign: "center",
                  marginRight: "20px",
                }}
                onClick={() => dispatch(changeTheme())}
                className="bp4-minimal"
                rightIcon={theme === "dark" ? "moon" : "flash"}
                text={theme === "dark" ? "Dark" : "Light"}
              />
            </Navbar.Group>
          </div>
          <LoadingSpinner />
          <NavbarGroup className={"mobile-view"}>
            <NavLink to={"/logout"}>
              <Button large={true} intent={"primary"}>
                LOGOUT
              </Button>
            </NavLink>
          </NavbarGroup>
          <NavbarGroup className={"mobile-icon"} align={Alignment.RIGHT}>
            <Button
              icon={"menu"}
              minimal={true}
              large={true}
              onClick={() => dispatch(activateMenu())}
            />
            <Drawer
              size={"60%"}
              isOpen={isOpen}
              canOutsideClickClose={true}
              autoFocus={true}
              enforceFocus={true}
              hasBackdrop={true}
              isCloseButtonShown={true}
              title={"Mobile Menu"}
              usePortal={true}
              onClose={() => dispatch(activateMenu())}
            >
              <div className={Classes.DRAWER_BODY}>
                <div className={Classes.DIALOG_BODY} style={{ margin: "0" }}>
                  <Menu large={true}>
                    <MenuItem
                      icon={"home"}
                      onClick={() => {
                        navigate("/home");
                        dispatch(activateMenu());
                      }}
                      text="Home"
                    />
                    <MenuItem
                      icon={"box"}
                      onClick={() => {
                        navigate("/assets-control");
                        dispatch(activateMenu());
                      }}
                      text="Assets Control"
                    />
                    <MenuDivider />

                    <MenuItem
                      icon={"log-out"}
                      onClick={() => {
                        dispatch(activateMenu());
                        navigate("/logout");
                      }}
                      text="Logout"
                    />
                  </Menu>
                </div>
              </div>
              <div className={Classes.DRAWER_FOOTER}>Footer</div>
            </Drawer>
          </NavbarGroup>
        </div>
      </Navbar>
    </>
  );
};

export default HomeNavbar;
